import { events as coreEvents } from "@photo-sphere-viewer/core";
import { events } from "@photo-sphere-viewer/virtual-tour-plugin";

export default class HashUpdater {
    private static _hashIsBeingUpdated = false;
    private static _hashToBeUpdated: {
        id?: string,
        yaw?: number,
        pitch?: number,
        editor?: string
    } = {};


    public static updatePosition = (e: coreEvents.PositionUpdatedEvent) => {
        this._hashToBeUpdated.yaw = Math.round(e.position.yaw * (1000)) / (1000);
        this._hashToBeUpdated.pitch = Math.round(e.position.pitch * (1000)) / (1000);
        this._updateHash();
    }

    public static updateId(nodeId: string) {
        this._hashToBeUpdated.id = nodeId;
        this._updateHash();
    }

    public static updateEditor(editor: string) {
        this._hashToBeUpdated.editor = editor;
        this._updateHash();
    }

    private static _updateHash() {
        if (this._hashToBeUpdated.editor !== undefined) {
            location.hash = this._hashToBeUpdated.editor;
            return;
        }
        if (!this._hashIsBeingUpdated) {
            setTimeout(() => {
                location.hash = `${this._hashToBeUpdated.id ?? ""}/${this._hashToBeUpdated.yaw ?? ""}/${this._hashToBeUpdated.pitch ?? ""}`;
                this._hashIsBeingUpdated = false;
            }, 1000);
            this._hashIsBeingUpdated = true;
        }
    }

    public static getPosition() {
        const hash = location.hash;
        const parts = hash.split("/");
        if (parts.length < 3) return undefined;
        return { id: parts[0].substring(1), yaw: Number(parts[1]) ?? 0, pitch: Number(parts[2]) };
    }

    public static getEditor() {
        return location.hash.substring(1);
    }
}