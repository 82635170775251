import { Viewer } from "@photo-sphere-viewer/core";
import { Marker, MarkerConfig, MarkersPlugin, events } from "@photo-sphere-viewer/markers-plugin";
import { _ } from "./locale";
import HTMLFactory from "./HTMLFactory";
import e from "express";
import InjectedNode from "./InjectedNode";
import VirtualTourViewer from "./VirtualTourViewer";
import { events as coreEvents } from "@photo-sphere-viewer/core";
import AbstractEditor from "./AbstractEditor";
import { VTBMarker } from "../config/model";

export default class MarkerEditor extends AbstractEditor {
    private _injectedNode: InjectedNode;
    private _virtualTourViewer: VirtualTourViewer;
    private _viewer: Viewer | undefined;
    private _currentPositionUpdatedHandler: ((e: coreEvents.PositionUpdatedEvent) => void) | undefined;
    private _currentClickHandler: ((e: coreEvents.ClickEvent) => void) | undefined;

    constructor(container: HTMLDivElement, rootPath: string, injectedNode: InjectedNode, virtualTourViewer: VirtualTourViewer) {
        super(container, rootPath);
        this._injectedNode = injectedNode;
        this._virtualTourViewer = virtualTourViewer;
    }

    public startListening(viewer: Viewer) {
        this._viewer = viewer;
        const markersPlugin = viewer.getPlugin(MarkersPlugin) as MarkersPlugin;
        markersPlugin.addEventListener("select-marker", this.show);
    }

    public show = (e?: events.SelectMarkerEvent | Marker) => {
        const marker = e ? (e.type === "select-marker" ? e.marker : e) : undefined; // ugly ternary
        this._viewer?.navbar.getButton("add-marker").disable();
        this._closeFullscreen();

        // remove pending event listeners
        if (this._currentClickHandler !== undefined) {
            this._viewer?.removeEventListener("click", this._currentClickHandler);
            this._currentClickHandler = undefined;
        }
        if (this._currentPositionUpdatedHandler !== undefined) {
            this._viewer?.removeEventListener("position-updated", this._currentPositionUpdatedHandler);
            this._currentPositionUpdatedHandler = undefined;
        }

        // if marker editor was just hidden (not destroyed), show it
        if (this._container.hasChildNodes()) {
            this._container.style.display = "inherit";
            return;
        }

        const radios = HTMLFactory.createDiv({
            htmlElements: HTMLFactory.createRadios([
                {
                    label: _("Info"),
                    value: "info"
                },
                {
                    label: _("Link"),
                    value: "link"
                },
                {
                    label: _("Video"),
                    value: "video"
                },
                {
                    label: _("Draw"),
                    value: "polygon"
                }
            ], "type", true)
        });
        const typeSpecificDiv = HTMLFactory.createDiv({});

        const form = document.createElement("form");
        form.append(
            HTMLFactory.createHeading(1, _("Edit marker")),
            marker ? "" : radios,
            HTMLFactory.createP([
                ...HTMLFactory.createTextInput({
                    name: "tooltip",
                    defaultValue: marker?.config.tooltip?.content ?? "",
                    label: `${_("Name:")} `,
                    required: true
                }),
                HTMLFactory.createHelp(_("Will be displayed when hovering over the marker. HTML is allowed."))
            ]),
            typeSpecificDiv
        );

        const cancelButton = HTMLFactory.createButton({ innerText: _("Cancel") });
        const removeButton = HTMLFactory.createButton({ innerText: _("Remove"), disabled: marker ? false : true });
        const moveButton = HTMLFactory.createButton({ innerText: _("Move/Redraw"), disabled: marker ? false : true });
        const submitButton = HTMLFactory.createButton({ innerText: e ? _("Submit") : _("Place") });

        form.append(
            HTMLFactory.createP([
                cancelButton,
                removeButton,
                moveButton,
                submitButton
            ])
        );

        const overlay = HTMLFactory.createOverlay([form]);
        this._container.append(overlay);

        if (e) {
            this._setType(typeSpecificDiv, marker)();
        } else {
            radios.addEventListener("change", this._setType(typeSpecificDiv));
        }
        cancelButton.addEventListener("click", ev => {
            ev.preventDefault();
            this.destroy();
            this._virtualTourViewer.update();
            this._viewer?.navbar.getButton("add-marker").enable();
        });
        removeButton.addEventListener("click", ev => {
            ev.preventDefault();
            if (confirm(_("Remove marker?"))) {
                this._removeMarker(marker!);
            }
        });
        moveButton.addEventListener("click", ev => {
            ev.preventDefault();
            this._moveMarker(marker!);
            this.hide();
        });
        form.addEventListener("submit", this._submit(marker));
    }

    public hide() {
        this._container.style.display = "none";
    }

    public destroy() {
        this._container.childNodes.forEach(node => node.remove());
    }

    private _submit(marker?: Marker, toggleFullscreen?: boolean) {
        return (e: SubmitEvent) => {
            e.preventDefault();

            const formData = new FormData(e.target as HTMLFormElement);
            const markerChanges = (marker?.config ?? { id: Date.now().toString() }) as VTBMarker;
            markerChanges.tooltip = { content: formData.get("tooltip") as string }
            if (formData.has("content")) markerChanges.content =
                `<h2>${markerChanges.tooltip.content}</h2>${formData.get("content") as string}`;
            if (!markerChanges.data) markerChanges.data = {};
            if (formData.has("link")) markerChanges.data.url = formData.get("link") as string;
            if (formData.has("type")) markerChanges.data.type = formData.get("type") as string;
            if (formData.has("video") && (formData.get("video") as File).size > 0) {
                const video = formData.get("video") as File;
                markerChanges.data.videoBlob = video;
                markerChanges.videoLayer = URL.createObjectURL(video);
                markerChanges.size = {
                    height: 0,
                    width: 0
                };
                markerChanges.chromaKey = {
                    ...markerChanges.chromaKey,
                    enabled: (document.getElementById("chroma-key-toggle") as HTMLInputElement).checked
                };
            }
            if (formData.has("color")) markerChanges.data.color = formData.get("color") as string;

            const node = this._injectedNode.node;
            const markers = node.markers ?? [];
            const markerIndex = markers.findIndex(m => (m.id === marker?.id));
            if (markerIndex >= 0) {
                // marker shall be modified
                if (markerChanges.data.type === "polygon") {
                    markerChanges.polygon = markerChanges.polyline ?? markerChanges.polygon;
                    delete markerChanges.polyline;
                    markerChanges.svgStyle = {
                        fill: markerChanges.data.color ? markerChanges.data.color + "33" : "#c8000033",
                        stroke: markerChanges.data.color ? markerChanges.data.color + "cc" : "#c80000cc",
                        strokeWidth: "3px"
                    }
                }

                markers[markerIndex] = {
                    ...markers[markerIndex],
                    ...markerChanges
                };
            } else {
                // marker shall be added
                const type = formData.get("type") as string;

                if (type === "polygon") {
                    this._drawMarker(markerChanges);
                    this.destroy();
                    return;
                }

                if (type === "link" || type === "info") {
                    markerChanges.image = `/assets/icons/pin-yellow-${type}.png`; // add rootPath
                }

                if (marker) {
                    // marker gets pushed to injectedNode
                    if (marker.data.type === "polygon") {
                        markerChanges.polygon = markerChanges.polyline;
                        delete markerChanges.polyline;
                        markerChanges.svgStyle = {
                            fill: markerChanges.data.color ? markerChanges.data.color + "33" : "#c8000033",
                            stroke: markerChanges.data.color ? markerChanges.data.color + "cc" : "#c80000cc",
                            strokeWidth: "3px"
                        }
                    }
                    markers.push(markerChanges);
                    node.markers = markers;
                } else {
                    // marker gets added to viewer
                    markerChanges.position = {
                        yaw: 0,
                        pitch: 0
                    };
                    if (markerChanges.data.type !== "video") {
                        markerChanges.size = {
                            height: 60,
                            width: 60,
                        }
                    }
                    const markersPlugin = this._viewer?.getPlugin(MarkersPlugin) as MarkersPlugin;
                    markersPlugin.addMarker(markerChanges as MarkerConfig);
                    if (markerChanges.data.type === "video") {
                        const videoElement = document.createElement("video");
                        videoElement.addEventListener("loadedmetadata", () => {
                            markersPlugin.updateMarker({
                                id: markerChanges.id,
                                size: {
                                    width: videoElement.videoWidth,
                                    height: videoElement.videoHeight
                                },
                                data: {
                                    originalSize: {
                                        width: videoElement.videoWidth,
                                        height: videoElement.videoHeight
                                    }
                                }
                            });
                            (document.getElementById("size-slider") as HTMLInputElement).value =
                                videoElement.videoWidth.toString();
                        });
                        videoElement.src = markersPlugin.getMarker(markerChanges as MarkerConfig).config.videoLayer!;
                        videoElement.load();
                    }
                    this._moveMarker(markersPlugin.getMarker(markerChanges as MarkerConfig));
                    this.destroy();
                    return;
                }
            }
            this.destroy();
            this._virtualTourViewer.update();
            this._viewer?.navbar.getButton("add-marker").enable();
        }
    }

    private _removeMarker(marker: Marker) {
        const markers = this._injectedNode.node.markers;
        const markerIndex = markers?.findIndex(m => m.id === marker.id);
        if (markerIndex !== undefined && markerIndex >= 0) {
            markers?.splice(markerIndex, 1);
        }
        this.destroy();
        this._virtualTourViewer.update();
        this._viewer?.navbar.getButton("add-marker").enable();
    }

    private _moveMarker(marker: Marker) {
        if (!this._viewer) return;
        if (marker.data.type === "polygon") {
            this._drawMarker(marker.config);
            return;
        }
        this._viewer?.overlay.show(_("Move the marker by moving your view. To confirm, click on the marker."));

        // remove all other markers to prevent a click on them
        const markersPlugin = this._viewer?.getPlugin(MarkersPlugin) as MarkersPlugin;
        const allMarkers = markersPlugin.getMarkers();
        allMarkers.forEach(m => {
            if (m.id !== marker.id) markersPlugin.removeMarker(m.id);
        });

        if (marker.data.type === "info" || marker.data.type === "link" || marker.data.type === "video") {
            this._currentPositionUpdatedHandler = (e: coreEvents.PositionUpdatedEvent) => {
                markersPlugin.updateMarker({
                    id: marker.id,
                    position: {
                        yaw: e.position.yaw,
                        pitch: e.position.pitch
                    }
                });
            }
            this._viewer?.addEventListener("position-updated", this._currentPositionUpdatedHandler);
        }

        if (marker.data.type === "video") {
            if (marker.config.chromaKey?.enabled) {
                this._viewer?.navbar.setCaption(`<div class="video-settings chroma-settings">\
                <label for="size-slider">${_("Width")}</label>\
                <label for="chroma-color">${_("Key color")}</label>\
                <label for="similarity">${_("Similarity")}</label>\
                <label for="smoothness">${_("Smoothness")}</label>\
                <input type="range" min="1" max="2880" \
            step="1" title="${_("Width")}" value="${marker.config.size?.width ?? 0}" \
            id="size-slider"><input type="color" id="chroma-color" \
            value="${marker.config.chromaKey?.color ?? "#00ff00"}">\
            <input type="range" min="0" max="1" step="0.01" id="similarity" \
            value="${marker.config.chromaKey?.similarity ?? 0.2}">\
            <input type="range" min="0" max="1" step="0.01" id="smoothness" \
            value="${marker.config.chromaKey?.smoothness ?? 0.2}"></div>`);

                (document.getElementById("chroma-color") as HTMLInputElement).addEventListener("change", e => {
                    const target = e.target as HTMLInputElement;
                    markersPlugin.updateMarker({
                        id: marker.id,
                        chromaKey: { enabled: true, color: target.value }
                    });
                });
                (document.getElementById("similarity") as HTMLInputElement).addEventListener("input", e => {
                    const target = e.target as HTMLInputElement;
                    markersPlugin.updateMarker({
                        id: marker.id,
                        chromaKey: { enabled: true, similarity: Number(target.value) }
                    });
                });
                (document.getElementById("smoothness") as HTMLInputElement).addEventListener("input", e => {
                    const target = e.target as HTMLInputElement;
                    markersPlugin.updateMarker({
                        id: marker.id,
                        chromaKey: { enabled: true, smoothness: Number(target.value) }
                    });
                });
            } else {
                this._viewer?.navbar.setCaption(`<div class="video-settings">\
                <label for="size-slider">${_("Width")}</label>\
                <input type="range" min="1" max="2880" step="1" title="${_("Width")}"\
                value="${marker.config.size?.width ?? 0}" id="size-slider"></div>`);
            }

            (document.getElementById("size-slider") as HTMLInputElement).addEventListener("input", e => {
                const target = e.target as HTMLInputElement;
                const aspectRatio = (marker.data?.originalSize?.width ?? 1) / (marker.data?.originalSize?.height ?? 1);
                markersPlugin.updateMarker({
                    id: marker.id,
                    size: {
                        width: Number(target.value),
                        height: Number(target.value) / aspectRatio
                    }
                });
            });
        }
    }

    private _drawMarker(markerConfig: MarkerConfig) {
        this._viewer?.overlay.show(_("Add points by left-clicking, undo points by right-clicking and finish by clicking the line."));

        const markersPlugin = this._viewer?.getPlugin(MarkersPlugin) as MarkersPlugin;
        // remove all other markers to prevent a click on them
        const allMarkers = markersPlugin.getMarkers();
        allMarkers.forEach(m => {
            if (m.id !== markerConfig.id) markersPlugin.removeMarker(m.id);
        });

        this._currentClickHandler = (e: coreEvents.ClickEvent) => {
            if (e.data.rightclick) {
                try {
                    // update marker
                    const marker = markersPlugin.getMarker(markerConfig.id);
                    const polyline = marker.config.polyline as [number, number][] ?? [];
                    if (polyline.length < 3) {
                        markersPlugin.removeMarker(marker.id);
                    } else {
                        polyline.pop();
                        markersPlugin.updateMarker({
                            id: marker.id,
                            polyline: polyline,
                            svgStyle: {
                                stroke: marker.data.color ? marker.data.color + "cc" : "#c80000cc",
                                strokeWidth: "3px"
                            }
                        });
                    }
                } catch (err) {
                    // console.warn("The following warning is expected.");
                    // console.warn(err);
                }
            } else {
                // add point
                try {
                    // update marker
                    const marker = markersPlugin.getMarker(markerConfig.id);
                    const polyline = marker.config.polyline as [number, number][] ?? [];
                    polyline.push([e.data.yaw, e.data.pitch]);
                    markersPlugin.updateMarker({
                        id: marker.id,
                        polyline: polyline
                    });
                } catch (err) {
                    // console.warn("The following warning is expected.");
                    // console.warn(err);

                    // remove marker if existing
                    if (markersPlugin.getMarkers().findIndex(marker => marker.id === markerConfig.id) > -1) {
                        markersPlugin.removeMarker(markerConfig.id);
                    }
                    // add marker
                    markerConfig.polyline = markerConfig.polygon ?? [[e.data.yaw, e.data.pitch], [e.data.yaw, e.data.pitch]];
                    markerConfig.polygon = undefined;
                    markerConfig.svgStyle = {
                        stroke: markerConfig.data.color ? markerConfig.data.color + "cc" : "#c80000cc",
                        strokeWidth: "3px"
                    };
                    markersPlugin.addMarker(markerConfig);
                }
            }
        }
        this._viewer?.addEventListener("click", this._currentClickHandler);
    }

    private _setType(container: HTMLDivElement, marker?: Marker) {
        return (e?: Event) => {
            const type = e ? (e.target as HTMLInputElement).value : marker?.data.type;

            switch (type) {
                case "video":
                    if (e) {
                        container.replaceChildren(
                            HTMLFactory.createP([
                                ...HTMLFactory.createFileInput({
                                    name: "video",
                                    accept: "video/*",
                                    label: `${_("Video file:")} `,
                                    required: marker?.config.videoLayer ? false : true
                                }),
                                HTMLFactory.createHelp(_("The video will get converted on the server side. The conversion is NOT lossless!"))
                            ]),
                            ...HTMLFactory.createCheckbox({
                                id: "chroma-key-toggle",
                                label: _("Enable chroma key"),
                                checked: marker?.config.chromaKey?.enabled ?? false
                            },)
                        );
                    }
                    break;
                case "polygon":
                    container.replaceChildren(
                        HTMLFactory.createP([
                            ...HTMLFactory.createTextInput({
                                name: "content",
                                type: "textarea",
                                label: `${_("Content:")} `,
                                defaultValue: marker?.config.content && marker.config.tooltip?.content ?
                                    marker.config.content.substring(9 + marker.config.tooltip.content.length) :
                                    undefined
                                // defaultValue: marker?.config.content
                            }),
                            HTMLFactory.createHelp(_("Will be displayed when clicking the marker. The name of the marker is automatically prepended as heading. HTML is allowed."))
                        ]),
                        HTMLFactory.createP(
                            HTMLFactory.createColorpicker({
                                name: "color",
                                label: _("Color: "),
                                defaultValue: marker?.config.data.color ?? "#c80000"
                            })
                        )
                    );
                    break;

                case "info":
                    container.replaceChildren(
                        HTMLFactory.createP([
                            ...HTMLFactory.createTextInput({
                                name: "content",
                                type: "textarea",
                                label: `${_("Content:")} `,
                                defaultValue: marker?.config.content && marker.config.tooltip?.content ?
                                    marker.config.content.substring(9 + marker.config.tooltip.content.length) :
                                    undefined
                                // defaultValue: marker?.config.content
                            }),
                            HTMLFactory.createHelp(_("Will be displayed when clicking the marker. The name of the marker is automatically prepended as heading. HTML is allowed."))
                        ])
                    );
                    break;
                case "link":
                    container.replaceChildren(
                        HTMLFactory.createP(
                            HTMLFactory.createTextInput({
                                name: "link",
                                type: "url",
                                label: `${_("URL:")} `,
                                defaultValue: marker?.data.url
                            })
                        )
                    );
                    break;
            }
        };
    }

    private _closeFullscreen() { // Props: https://www.w3schools.com/Jsref/met_element_exitfullscreen.asp
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if ((document as any).webkitExitFullscreen) { /* Safari */
            (document as any).webkitExitFullscreen();
        } else if ((document as any).msExitFullscreen) { /* IE11 */
            (document as any).msExitFullscreen();
        }
    }
}