import { Config } from "../config/model";
import * as CONSTANTS from "../config/CONSTANTS";

let config: Required<Config> | undefined = undefined;

export async function getConfig(): Promise<Required<Config>> {
    if (config === undefined) {
        const response = await fetch(`${CONSTANTS.RESOURCES_DIR_URI}config.json?nocache=${Date.now()}`);
        if (!response.ok) {
            console.error(`[getConfig] error: ${response.status}`);
        } else {
            const json = await response.json();
            config = json;
        }
        config = {
            lang: "en",
            mapExtend: [0,0,1,1],
            mapSize: {
                width: 1000,
                height: 1000
            },
            ...config
        };
    }
    return config;
}