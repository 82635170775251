export const THUMBNAIL_HEIGHT = 60;
export const THUMBNAIL_WIDTH = 60;
export const PIN_HEIGHT = 50;
export const PIN_WIDTH = 50;

export const ASSETS_DIR_URI = "/assets/"
export const IMAGES_FULL_DIR_URI = `${ASSETS_DIR_URI}images/full/`;
export const IMAGES_BASE_DIR_URI = `${ASSETS_DIR_URI}images/base/`;
export const IMAGES_TILES_DIR_URI = `${ASSETS_DIR_URI}images/tiles/`;
export const ICONS_DIR_URI = ASSETS_DIR_URI + "icons/";
export const VIDEOS_DIR_URI = ASSETS_DIR_URI + "videos/";
export const THUMBNAILS_DIR_URI = ASSETS_DIR_URI + "thumbnails/";
export const RESOURCES_DIR_URI = "/resources/";
export const JSON_MARKERS_DIR_URI = RESOURCES_DIR_URI +"json/markers/";
export const JSON_NODES_FILE_URI = RESOURCES_DIR_URI +"json/nodes.json";

export const CONFIG_URI = `${RESOURCES_DIR_URI}config.json`;
export const LANG_DIR_URI = `${RESOURCES_DIR_URI}lang/`;

export const URI_PREFIX = "/virtual-tour-builder/";