import { Viewer } from "@photo-sphere-viewer/core";
import { MarkersPlugin, events } from "@photo-sphere-viewer/markers-plugin";
import { events as coreEvents } from "@photo-sphere-viewer/core";
import { _ } from "./locale";
import { VirtualTourPlugin } from "@photo-sphere-viewer/virtual-tour-plugin";

export default class MarkerHandler {
    private _viewer: Viewer;
    private _markersPlugin: MarkersPlugin;
    private _currentActiveVideo: HTMLVideoElement | undefined;
    private _currentVideos: HTMLVideoElement[];

    constructor(viewer: Viewer, editMode?: boolean) {
        this._viewer = viewer;
        this._markersPlugin = this._viewer.getPlugin(MarkersPlugin);
        this._currentVideos = [];
        if (!editMode) {
            this._markersPlugin.addEventListener("select-marker", this._handleMarkerSelect);
            this._viewer.addEventListener("key-press", this._handleKeyPress);
        }
    }

    public checkForSpecialMarkers() {
        this._currentVideos.forEach(video => {
            video.pause();
            video.remove();
        });
        this._currentVideos = [];

        const markers = this._markersPlugin.getMarkers();
        markers.forEach(marker => {
            if (marker.type === "videoLayer") {
                marker.video.pause();
                marker.video.style.backgroundColor = "transparent";
                marker.video.loop = false;
                marker.video.muted = false;
                marker.video.addEventListener("ended", () => {
                    this._markersPlugin.removeMarker(marker.config);
                    const currentNodeName = (this._viewer.getPlugin(VirtualTourPlugin) as VirtualTourPlugin).getCurrentNode().name;
                    this._viewer.navbar.setCaption(currentNodeName ?? "");
                });
                this._currentVideos.push(marker.video);
            }
            if (marker.data.linkedHover) {
                this._hoverMulitpleElements(marker.domElement, marker.data.linkedHover);
            }
        });
    }

    private _hoverMulitpleElements(element: HTMLElement | SVGElement, className: string) { // TODO: Implement in interface
        element.addEventListener('mouseenter', e => {
            let elements = document.querySelectorAll(`.${className}`);
            let elementsArray = Array.prototype.slice.call(elements);
            elementsArray.forEach((elem: HTMLElement) => {
                elem.style.opacity = "0";
            });
        });
        element.addEventListener('mouseleave', e => {
            let elements = document.querySelectorAll(`.${className}`);
            let elementsArray = Array.prototype.slice.call(elements);
            elementsArray.forEach((elem: HTMLElement) => {
                elem.style.opacity = "1";
            });
        });
    }

    private _handleMarkerSelect = async (e: events.SelectMarkerEvent) => {
        await this._markersPlugin.gotoMarker(e.marker.config);
        if (e.marker.type === "videoLayer") {
            const video = e.marker.video;
            if (video !== this._currentActiveVideo) {
                // prevent mulitple videos playing at the same time
                this._currentActiveVideo?.pause();
            }
            if (video.paused) {
                const currentTime = Math.floor((100 / video.duration) * video.currentTime);
                this._viewer.navbar.setCaption(`<div class="video-controls">\
                <span id="video-time">00:00</span>
                <input type="range" min="0" max="100" value=${currentTime} id="video-slider" title="${_("Current time")}">
                <span>&#128266;</span>
                <input type="range" min=0" max="100" value="${Math.floor(video.volume * 100)}" \
                id="volume-slider" title="${_("Volume")}"></div>`);
                video.addEventListener("timeupdate", this._videoBarHandler(video));
                document.getElementById("video-slider")?.addEventListener("input", e => {
                    video.currentTime = video.duration * (Number((e.target as HTMLInputElement).value) / 100);
                });
                document.getElementById("volume-slider")?.addEventListener("input", e => {
                    video.volume = Number((e.target as HTMLInputElement).value) / 100;
                });
                video.play();
                this._currentActiveVideo = video;
            } else {
                e.marker.video.pause();
                video.removeEventListener("timeupdate", this._videoBarHandler(video));
            }
        }
        if (e.marker.data.type === "link") {
            window.open(e.marker.data.url, "_blank");
        }
    }

    private _handleKeyPress = (e: coreEvents.KeypressEvent) => {
        if (e.key !== " " && e.key !== "Spacebar") return;
        if (!this._currentActiveVideo) return;
        if (this._currentActiveVideo.paused) {
            this._currentActiveVideo.play();
        } else {
            this._currentActiveVideo.pause();
        }
    }

    private _videoBarHandler(video: HTMLVideoElement) {
        return () => {
            const currentTime = Math.floor((100 / video.duration) * video.currentTime);
            (document.getElementById("video-slider") as HTMLInputElement).value = currentTime.toString();
            (document.getElementById("video-time") as HTMLInputElement).innerText =
                `${(video.currentTime / 60).toLocaleString(undefined, {
                    minimumIntegerDigits: 2,
                    maximumFractionDigits: 0,
                    useGrouping: false
                })}:${(video.currentTime % 60).toLocaleString(undefined, {
                    minimumIntegerDigits: 2,
                    maximumFractionDigits: 0,
                    useGrouping: false
                })}`;
        }

    }
}