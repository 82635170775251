import { VTBNode } from "../config/model";
import InjectedNode from "./InjectedNode";
import * as CONSTANTS from "../config/CONSTANTS";

export default class NodeList {
    private _rootPath: string;
    private _list: VTBNode[] | undefined;
    private _injectedNode: InjectedNode | undefined;
    private _editMode: boolean;

    constructor(rootPath: string, editMode = false) {
        this._rootPath = rootPath;
        this._editMode = editMode;
    }

    public getList() {
        const nodeList = this._list ? [...this._list] : [];
        if (this._injectedNode?.hasNode()) {
            // duplicate list to make changes
            const nodeToInject = this._injectedNode.node;

            // prepend the injected node
            const indexOfNode = nodeList.findIndex(node => node.id === nodeToInject.id);

            if (indexOfNode > -1) {
                // node shall be modified
                nodeList[indexOfNode] = { ...nodeList[indexOfNode], ...nodeToInject };
            } else {
                nodeList.push(nodeToInject);
            }
        }
        return nodeList;
    }

    public set injectedNode(injectedNode: InjectedNode) {
        this._injectedNode = injectedNode;
    }

    public async updateList() {
        const response = await fetch(`${this._rootPath}resources/json/nodes.json${this._editMode ? "?nocache=" + Date.now() : ""}`);
        if (!response.ok) {
            throw new Error(`HTTP Error: ${response.status}`);
        }
        this._list = await response.json();
        this._list?.forEach(node => {
            const size = node.data?.panoramaSize ?? {width: 5760, height: 2880};
            if (!node.panorama) {
                node.panorama = {
                    width: size.width,
                    cols: 16,
                    rows: 8,
                    baseUrl: `${CONSTANTS.IMAGES_BASE_DIR_URI}${node.id}.jpg${this._editMode ? "?nocache=" + Date.now() : ""}`,
                    tileUrl: (col: number, row: number) => {
                        return `${CONSTANTS.IMAGES_TILES_DIR_URI}${node.id}/tile${col + (row * 16)}.jpg${this._editMode ? "?nocache=" + Date.now() : ""}`;
                    }
                }
            }
        });
        return this._list!;
    }
}