import { VTBNode } from "../config/model";
import * as CONSTANTS from "../config/CONSTANTS";
import { Size } from "@photo-sphere-viewer/core";

export default class Uploader {
    public static uploadNode(node: Omit<VTBNode, "panorama">, rootPath = "/") {
        console.log("Upload node:");
        console.log(JSON.stringify(node));
        return fetch(`${CONSTANTS.URI_PREFIX}upload-node`, { // TODO: use rootPath
            method: "POST",
            body: JSON.stringify(node),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
    }

    public static removeNode(nodeId: string, rootPath = "/") {
        return fetch(`${CONSTANTS.URI_PREFIX}remove-node?node=${nodeId}`)
    }

    public static uploadPanorama(nodeId: string, panorama: File, rootPath = "/", size?: Size) {
        if (!size) size = {width: 5760, height: 2880};
        const formData = new FormData();
        formData.append("image", panorama, nodeId);
        formData.append("size", JSON.stringify(size));
        return fetch(`${CONSTANTS.URI_PREFIX}upload-panorama`, {
            method: "POST",
            body: formData
        });
    }

    public static uploadVideo(nodeId: string, markerId: string, video: File, rootPath = "/") {
        const formData = new FormData();
        formData.append("nodeId", nodeId);
        formData.append("video", video, markerId);
        return fetch(`${CONSTANTS.URI_PREFIX}upload-video`, {
            method: "POST",
            body: formData
        });
    }

    public static async uploadMap(map: File, rootPath = "/") {
        const size = await new Promise<{ width: number, height: number }>(resolve => {
            const image = new Image();
            image.addEventListener("load", () => {
                const size = { width: image.naturalWidth, height: image.naturalHeight };
                image.remove();
                URL.revokeObjectURL(image.src);
                resolve(size);
            });
            image.src = URL.createObjectURL(map);
            document.body.append(image);
        });

        const formData = new FormData();
        formData.append("map", map);
        formData.append("size", JSON.stringify(size));
        return fetch(`${CONSTANTS.URI_PREFIX}upload-map`, {
            method: "POST",
            body: formData
        });
    }

    public static setMapExtend(topLeft: [number, number], bottomRight: [number, number]) {
        return fetch(`${CONSTANTS.URI_PREFIX}set-map-extend?tllong=${topLeft[0]}&tllat=${topLeft[1]}&brlong=${bottomRight[0]}&brlat=${bottomRight[1]}`);
    }

    public static uploadLoader(loader: File, rootPath = "/") {
        const formData = new FormData();
        formData.append("loader", loader);
        return fetch(`${CONSTANTS.URI_PREFIX}upload-loader`, {
            method: "POST",
            body: formData
        });
    }

    public static setLanguage(lang: string) {
        return fetch(`${CONSTANTS.URI_PREFIX}set-language?lang=${lang}`);
    }
}