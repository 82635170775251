import { _ } from "./locale";
import { Chain, VTBLink, VTBNode } from "../config/model";
import { NodeEditor } from "./NodeEditor";
import { Position } from "@photo-sphere-viewer/core";
import Uploader from "./Uploader";
import { decode } from "html-entities";
import { getConfig } from "./config";

export default class HTMLFactory {
    // Returns a HTML table element consisting of one header row
    // and all nodes in alphabetical order (sorted by ID).
    public static createNodeTable(nodes: VTBNode[], rootPath: string, nodeEditor: NodeEditor) {
        const table = document.createElement("table");
        let row = table.insertRow();
        let cell = row.insertCell();
        cell.innerText = _("Preview");
        cell = row.insertCell();
        cell.innerText = _("ID");
        cell = row.insertCell();
        cell.innerText = _("Display name");
        cell = row.insertCell();
        cell.innerText = _("Remove");

        nodes.sort((a, b) => ('' + a.id).localeCompare(b.id));
        nodes.forEach(node => this.appendNode(rootPath, table, nodeEditor, node));

        return table;
    }

    public static appendNode(rootPath: string, table: HTMLTableElement, nodeEditor: NodeEditor, node?: VTBNode) {
        const row = table.insertRow();
        row.tabIndex = 0;

        if (!node) {
            const form = document.createElement("form");
            form.append(
                this.createTextInput({
                    required: true,
                    placeholder: _("ID"),
                    pattern: "[A-Za-z0-9_\\-]+",
                    patternHuman: "A-z a-z 0-9 _ -"
                })[0],
                this.createButton({ innerText: ` ${_("Add")}` })
            );
            row.insertCell();
            row.insertCell().append(form);

            form.addEventListener("submit", e => {
                e.preventDefault();
                const id = ((e.target as HTMLFormElement).firstChild as HTMLInputElement).value;
                nodeEditor.showOverlay(id.replace(/[^A-Za-z0-9_\-]+/g, "-"));
            });
            return;
        }

        const thumbnail = document.createElement("img");
        thumbnail.src = `${rootPath}assets/images/base/${node.id}.jpg?nocache=${(new Date()).getTime()}`;
        thumbnail.alt = _("Preview");

        row.insertCell().append(thumbnail);
        row.insertCell().innerText = node.id + (node.data?.startNode ? ` (${_("Start node")})` : "");
        row.insertCell().innerText = decode(node.name) ?? "";

        const removeButton = this.createButton({ innerText: _("Remove") });
        row.insertCell().append(removeButton);

        row.addEventListener("click", () => nodeEditor.showOverlay(node.id));
        row.addEventListener("keypress", e => e.key === "Enter" ? nodeEditor.showOverlay(node.id) : false);
        removeButton.addEventListener("click", (e: MouseEvent) => {
            e.stopPropagation();
            if (confirm(_("Remove node?"))) {
                Uploader.removeNode(node.id)
                    .then(() => location.reload());
            }
        });
    }

    public static createButton(object: {
        innerText: string, id?: string, hidden?: boolean,
        className?: string, disabled?: boolean, clickEvent?: (e: Event) => void
    }) {
        const button = document.createElement("button");
        if (object.innerText) button.innerText = object.innerText;
        if (object.id) button.id = object.id;
        if (object.className) button.className = object.className;
        if (object.disabled) button.disabled = object.disabled;
        if (object.clickEvent) button.addEventListener("click", object.clickEvent);
        if (object.hidden) button.hidden = object.hidden;
        return button;
    }

    public static createOverlay(htmlElements?: HTMLElement[]) {
        const innerDiv = this.createDiv({ htmlElements: htmlElements });
        const outerDiv = this.createDiv({ className: "overlay__background", htmlElements: [innerDiv] });
        return outerDiv;
    }

    public static async createMapOptions(src: string, showOnMap: boolean) {
        const showAllNodesCheckbox = this.createCheckbox({
            id: "show-all-nodes",
            checked: false,
            label: _("Show all nodes")
        });
        const showLinkedNodesCheckbox = this.createCheckbox({
            id: "show-linked-nodes",
            checked: true,
            label: _("Show linked nodes")
        });

        const outsideWrapper = this.createDiv({ className: "map outside-wrapper" });
        outsideWrapper.style.aspectRatio = `${(await getConfig()).mapSize.width}/${(await getConfig()).mapSize.height}`;
        const insideWrapper = this.createDiv({ className: "map inside-wrapper" });
        const img = document.createElement("img");
        // img.id = "map";
        img.alt = _("There is no map configured or your browser can't display it.");
        img.src = src;

        const canvas = document.createElement("canvas");
        canvas.height = (await getConfig()).mapSize.height;
        canvas.width = (await getConfig()).mapSize.width;
        canvas.id = "map-canvas";

        insideWrapper.append(img, canvas);
        outsideWrapper.append(insideWrapper);

        const showOnMapCheckbox = this.createCheckbox({
            id: "show-on-map",
            name: "show-on-map",
            checked: showOnMap,
            label: _("Show as hotspot on map")
        });

        return this.createDiv({
            id: "map-options",
            htmlElements: [
                this.createP([...showAllNodesCheckbox, ...showLinkedNodesCheckbox]),
                this.createP([outsideWrapper]),
                this.createP(showOnMapCheckbox)
            ]
        });
    }

    public static createPanTiltRollTable(pan: number, tilt: number, roll: number, changeListener: (e: Event) => void) {
        const table = document.createElement("table");
        table.id = "pan-tilt-roll-table";

        let row = table.insertRow();
        row.innerHTML = `<th><label for="pan">${_("Pan")}</label></th>
        <th><label for="tilt">${_("Tilt")}</label></th>
        <th><label for="roll">${_("Roll")}</label></th>`;

        row = table.insertRow();
        row.innerHTML = `<td><input type="range" min="-3.14" max="3.14" step="0.01" value="${pan}" id="pan"></td>
        <td><input type="range" min="-3.14" max="3.14" step="0.01" value="${tilt}" id="tilt"></td>
        <td><input type="range" min="-3.14" max="3.14" step="0.01" value="${roll}" id="roll"></td>`;

        table.addEventListener("change", changeListener);
        return table;
    }

    public static createTextInput(object: {
        name?: string, defaultValue?: string,
        placeholder?: string, required?: boolean, hidden?: boolean, id?: string,
        label?: string, pattern?: string, type?: string, inputEvent?: (e: Event) => void,
        form?: string, patternHuman?: string
    }) {
        const returnArray = [];

        if (object.label) {
            if (!object.id) object.id = `text-${Date.now()}`;

            const label = document.createElement("label");
            label.htmlFor = object.id;
            label.innerText = object.label;
            returnArray.push(label);
        }

        const input = document.createElement("input");
        input.type = object.type ?? "text";
        if (object.id) input.id = object.id;
        if (object.name) input.name = object.name;
        if (object.defaultValue) input.defaultValue = object.defaultValue;
        if (object.placeholder) input.placeholder = object.placeholder;
        if (object.required) input.required = true;
        if (object.hidden) input.hidden = true;
        if (object.pattern) {
            input.pattern = object.pattern;

            input.addEventListener("keyup", e => {
                if (input.validity.patternMismatch) {
                    input.setCustomValidity(_("Invalid input.") +
                        (object.patternHuman ? " " + _("Valid inputs are: %s", object.patternHuman) : ""));
                } else {
                    input.setCustomValidity("");
                }
                input.reportValidity();
            });
        }
        if (object.patternHuman) input.title = _("Valid inputs are: %s", object.patternHuman);
        if (object.inputEvent) input.addEventListener("input", object.inputEvent);
        if (object.form) input.setAttribute("form", object.form);
        returnArray.push(input);

        return returnArray;
    }

    public static createHeading(type: number, content: string) {
        const header = document.createElement(`h${type}`) as HTMLHeadingElement;
        header.innerText = content;
        return header;
    }

    public static createDiv(object: {
        id?: string, className?: string,
        innerHTML?: string, htmlElements?: (HTMLElement | string)[]
    }) {
        const div = document.createElement("div");
        if (object.id) div.id = object.id;
        if (object.className) div.className = object.className;
        if (object.innerHTML) div.innerHTML = object.innerHTML;
        if (object.htmlElements) div.append(...object.htmlElements);

        return div;
    }

    public static createP(elements?: (HTMLElement | string)[], className?: string) {
        const p = document.createElement("p");
        if (className) p.className = className;
        if (elements) p.append(...elements);

        return p;
    }

    public static createCheckbox(object: {
        id?: string, name?: string,
        checked?: boolean, label?: string, changeEvent?: (e: Event) => void,
        disabled?: boolean
    }) {
        const returnArray = [];
        if (object.label && !object.id) {
            object.id = `checkbox-${Date.now()}`;
        }

        const input = document.createElement("input");
        input.type = "checkbox";
        if (object.id) input.id = object.id;
        if (object.name) input.name = object.name;
        if (object.checked) input.checked = true;
        if (object.changeEvent) input.addEventListener("change", object.changeEvent);
        if (object.disabled) input.disabled = true;
        returnArray.push(input);

        if (object.label) {
            const label = document.createElement("label");
            label.htmlFor = object.id!;
            label.innerText = object.label;
            returnArray.push(label);
        }

        return returnArray;
    }

    public static createFileInput(object: {
        id?: string, name?: string,
        accept?: string, required?: boolean, label?: string, changeEvent?: (e: Event) => void
    }) {
        const returnArray = [];

        if (object.label) {
            if (!object.id) object.id = `file-${Date.now()}`;

            const label = document.createElement("label");
            label.htmlFor = object.id;
            label.innerText = object.label;
            returnArray.push(label);
        }

        const input = document.createElement("input");
        input.type = "file";
        if (object.id) input.id = object.id;
        if (object.name) input.name = object.name;
        if (object.accept) input.accept = object.accept;
        if (object.required) input.required = true;
        if (object.changeEvent) input.addEventListener("change", object.changeEvent);
        returnArray.push(input);

        return returnArray;
    }

    public static createA(object: {
        id?: string, className?: string, href?: string,
        innerText?: string, target?: string
    }) {
        const a = document.createElement("a");
        if (object.id) a.id = object.id;
        if (object.className) a.className = object.className;
        if (object.href) a.href = object.href;
        if (object.innerText) a.innerText = object.innerText;
        if (object.target) a.target = object.target;

        return a;
    }

    public static createRadios(radios: { label: string, value: string }[], name: string, required?: boolean) {
        const output: (HTMLLabelElement | HTMLInputElement)[] = [];
        let id = Date.now();
        radios.forEach(radio => {

            const input = document.createElement("input");
            input.type = "radio";
            input.id = `radio-${id}`;
            input.name = name;
            input.value = radio.value;
            input.required = required ?? false;
            output.push(input);

            const label = document.createElement("label");
            label.htmlFor = `radio-${id++}`;
            label.innerText = radio.label;
            output.push(label);
        });
        return output;
    }

    public static createColorpicker(object: {
        id?: string, name?: string,
        label?: string, defaultValue?: string, hidden?: boolean
    }) {
        const output: (HTMLInputElement | HTMLLabelElement)[] = [];

        if (object.label) {
            object.id = object.id ?? Date.now().toString();
            const labelElement = document.createElement("label");
            labelElement.htmlFor = object.id;
            labelElement.innerText = object.label;
            output.push(labelElement);
        }

        const input = document.createElement("input");
        input.type = "color";
        if (object.id) input.id = object.id;
        if (object.defaultValue) input.defaultValue = object.defaultValue
        if (object.name) input.name = object.name;
        if (object.hidden) input.hidden = true;
        output.push(input);

        return output;
    }

    public static createHelp(help: string) {
        const span = document.createElement("span");
        span.className = "help";
        span.innerText = "?";
        const helpP = this.createP([help], "help-text");
        span.addEventListener("click", () => {
            if (span.classList.contains("visible")) {
                span.classList.remove("visible");
                helpP.remove();
            } else {
                span.classList.add("visible");
                span.after(helpP);
            }
        });
        return span;
    }

    public static createRange(object: {
        id?: string, min?: number, max?: number,
        defaultValue?: number, step?: number
    }) {
        const range = document.createElement("input");
        range.type = "range";
        if (object.id) range.id = object.id;
        if (object.min !== undefined) range.min = object.min.toString();
        if (object.max !== undefined) range.max = object.max.toString();
        if (object.step !== undefined) range.step = object.step.toString();
        if (object.defaultValue !== undefined) range.defaultValue = object.defaultValue.toString();
        return range;
    }

    public static createSelect(object: {options?: {value: string, text: string}[],
        label?: string, name?: string, value?: string}) {
        const returnArray = [];
        if (object.label) {
            const label = document.createElement("label");
            label.innerText = object.label;
            returnArray.push(label);
        }

        object.options?.sort((a, b) => a.text.localeCompare(b.text));
        const select = document.createElement("select");
        object.options?.forEach(option => {
            const optionElem = document.createElement("option");
            optionElem.value = option.value;
            optionElem.innerText = option.text;
            select.append(optionElem);
        });

        if (object.name) select.name = object.name;
        if (object.value) select.value = object.value;
        returnArray.push(select);
        return returnArray;
    }
}