import * as CONSTANTS from "../config/CONSTANTS";
import { sprintf } from "sprintf-js";
import { getConfig } from "./config";

let lang = "";
const defaultLang = "en";
let langObj: any = {};

export async function initTranslate() {
    const config = await getConfig();
    lang = config.lang;
    if (lang === "en") return;

    const res = await fetch(`${CONSTANTS.LANG_DIR_URI}${lang}.json`);
    if (!res.ok) {
        console.error(`HTTP Error: ${res.status}`);
        return;
    }
    langObj = await res.json();
}

export function _(string: string, ...args: any[]) {
    if (lang === "en") return sprintf(string, ...args);
    
    let result = langObj[string];

    if (result === '' || result === undefined) {
        result = string;
        console.log("Missed translation: \"" + string + "\"");
    }

    try {
        return sprintf(result, ...args);
    } catch (e) {
        return `${result} ${args.join(', ')} (Translation error: ${e})`;
    }
}