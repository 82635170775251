import { Point, Position, Viewer } from "@photo-sphere-viewer/core";
import NodeList from "./NodeList";
import InjectedNode from "./InjectedNode";
import { EquirectangularTilesAdapter } from "@photo-sphere-viewer/equirectangular-tiles-adapter";
import * as CONSTANTS from "../config/CONSTANTS";
import { MarkersPlugin } from "@photo-sphere-viewer/markers-plugin";
import { VirtualTourLink, VirtualTourNode, VirtualTourPlugin, events } from "@photo-sphere-viewer/virtual-tour-plugin";
import { MapHotspot, MapPlugin } from "@photo-sphere-viewer/map-plugin";
import { _, initTranslate } from "./locale";
import "../styles/VirtualTourViewer.scss";
import { VTBNode } from "../config/model";
import HashUpdater from "./HashUpdater";
import MarkerHandler from "./MarkerHandler";
import MarkerEditor from "./MarkerEditor";
import HTMLFactory from "./HTMLFactory";
import { getConfig } from "./config";

export default class VitualTourViewer {
    private _dummyNode: VTBNode;
    private _rootPath: string;
    private _container: HTMLDivElement;
    private _nodeList: NodeList;
    private _editMode: boolean;
    private _viewer: Viewer | undefined;
    private _markerHandler: MarkerHandler | undefined;
    private _markerEditor: MarkerEditor | undefined;

    constructor(rootPath: string, container: HTMLDivElement, nodeList?: NodeList, injectedNode?: InjectedNode) {
        this._editMode = injectedNode ? true : false;
        this._rootPath = rootPath;
        this._container = container;
        this._nodeList = nodeList ?? new NodeList(rootPath, this._editMode);
        this._nodeList.updateList();
        this._dummyNode = {
            id: `dummy-${Date.now()}`,
            gps: [0, 0],
            panorama: {
                width: 5760,
                cols: 16,
                rows: 8,
                baseUrl: `${rootPath}assets/images/dummy-panorama.jpg`,
                tileUrl: (col: number, row: number) => ""
            }
        }
        if (injectedNode) {
            const div = HTMLFactory.createDiv({ className: "marker-editor" });
            this._container.append(div);
            this._markerEditor = new MarkerEditor(div, rootPath, injectedNode, this);
        }
    }

    public get currentNode() {
        const plugin = this._viewer?.getPlugin(VirtualTourPlugin);
        if (plugin instanceof VirtualTourPlugin) {
            return plugin.getCurrentNode();
        }
        throw new Error("No viewer initialized.");
    }

    public get viewer() {
        return this._viewer;
    }

    public hasViewer() {
        return this._viewer ? true : false;
    }

    public async show(startNodeId?: string) {
        await initTranslate();
        const nodeList = this._nodeList.getList();
        if (nodeList.length < 1) return;

        if (!this._viewer) {
            let startPosition: (Position & { id?: string }) | undefined = HashUpdater.getPosition();
            // Priority start node: Given start node > node in hash >
            // start node saved in json > first node in array
            startNodeId = startNodeId ?? startPosition?.id;
            if (startNodeId === undefined) {
                nodeList.forEach(node => {
                    if (!node.data?.startNode) return;
                    startNodeId = node.id;
                    startPosition = node.data.startPosition;
                });

            }
            await this._initViewer(startPosition?.yaw, startPosition?.pitch);

            (this._viewer!.getPlugin(VirtualTourPlugin) as VirtualTourPlugin).setNodes(nodeList, startNodeId);
        }

        this._container.style.display = "inherit";
    }

    public update(startNodeId?: string, hardUpdate?: boolean) {
        if (!this._viewer) return;
        const virtualTourPlugin = this._viewer.getPlugin(VirtualTourPlugin) as VirtualTourPlugin;
        if (!startNodeId) {
            startNodeId = virtualTourPlugin.getCurrentNode().id;
        }
        // prevent too fast updating
        if (startNodeId === this._dummyNode.id) return;

        if (hardUpdate) {
            this._viewer.destroy();
            this._viewer = undefined;
            this._initViewer();
        }

        const nodeList = this._nodeList.getList();
        if (nodeList.length < 1) return;

        // dummy node is needed so that viewer actually updates anything.
        console.warn("The following one or two warnings about a dummy node are expected.");
        virtualTourPlugin.setNodes([this._dummyNode]);
        setTimeout(() => virtualTourPlugin.setNodes(nodeList, startNodeId), 0);
    }

    public hide() {
        this._container.style.display = "none";
    }

    public destroy() {
        if (this._viewer) {
            this._viewer.destroy();
            this._viewer = undefined;
        }
    }

    private async _initViewer(yaw?: number, pitch?: number) {
        const config = await getConfig();

        const viewerDiv = HTMLFactory.createDiv({ className: "psv-viewer" });
        this._container.append(viewerDiv);
        this._viewer = new Viewer({
            container: viewerDiv,
            adapter: [EquirectangularTilesAdapter, {
                baseBlur: false
            }],
            loadingImg: `${CONSTANTS.ASSETS_DIR_URI}loader.apng`,
            plugins: [
                [VirtualTourPlugin, {
                    map: {
                        imageUrl: `${CONSTANTS.ASSETS_DIR_URI}map.jpg`,
                        size: config.mapSize ?? { width: 1000, height: 1000 },
                        extent: config.mapExtend ?? [0, 0, 1, 1],
                    },
                    positionMode: "gps",
                    getLinkTooltip: (content: string, link: VirtualTourLink, node: VirtualTourNode) => {
                        return `<p>${link.data.tooltip ? link.data.tooltip : node.name}</p>`;
                    }
                }],
                [MapPlugin, {
                    static: true,
                    defaultZoom: 40,
                }],
                [MarkersPlugin, {
                    defaultHoverScale: 1.2
                }],
            ],
            navbar: [
                "caption",
                "fullscreen"
            ],
            // mousewheelCtrlKey: this._editMode,
            // touchmoveTwoFingers: this._editMode,
            lang: {
                zoom: _("Zoom"),
                zoomOut: _("Zoom out"),
                zoomIn: _("Zoom in"),
                moveUp: _("Move up"),
                moveDown: _("Move down"),
                moveLeft: _("Move left"),
                moveRight: _("Move right"),
                download: _("Download"),
                fullscreen: _("Fullscreen"),
                menu: _("Menu"),
                close: _("Close"),
                twoFingers: _("Use two fingers to navigate"),
                ctrlZoom: _("Use ctrl + scroll to zoom the image"),
                loadError: _("The panorama can't be loaded"),
                map: _("Map"),
                mapMaximize: _("Maximize"),
                mapMinimize: _("Minimize"),
                mapReset: _("Reset"),
                markers: _("Markers"),
                markersList: _("Markers list"),
                settings: _("Settings"),
            },
            loadingTxt: _("Loading..."),
            defaultYaw: yaw ?? 0,
            defaultPitch: pitch ?? 0
        });

        if (!this._editMode) {
            this._viewer.addEventListener("position-updated", HashUpdater.updatePosition)
        } else {
            this._markerEditor?.startListening(this._viewer);
            this._viewer.navbar.setButtons([
                "caption",
                {
                    id: "add-marker",
                    content: _("Add Marker"),
                    title: _("Add Marker"),
                    onClick: (viewer) => this._markerEditor?.show(),
                },
                "fullscreen"
            ]);
        }
        this._markerHandler = new MarkerHandler(this._viewer, this._editMode);
        this._viewer.getPlugin(VirtualTourPlugin).addEventListener("node-changed", this._afterNodeChanged);
    }

    private _afterNodeChanged = (e: events.NodeChangedEvent) => {
        if (this._editMode) {
            if (e.data.fromLink) {
                this._manualChangeNode(e.data.fromNode.id);
                this._viewer!.notification.show({
                    content: _("Node-switching disabled in edit mode."),
                    timeout: 5000
                });
            }
        } else {
            HashUpdater.updateId(e.node.id);
            const chain = (e.node as VTBNode).data?.chain;
            const chainTo = chain?.find(c => c.fromNode === e.data.fromNode?.id)?.toNode;
            if (chainTo) {
                this._manualChangeNode(chainTo, e.node);
                return;
            }
        }
        this._markerHandler?.checkForSpecialMarkers();
        this._setHotspots();
    }


    private _manualChangeNode(toNodeId: string, fromNode?: VirtualTourNode) {
        if (!this._viewer) return;

        const link = fromNode?.links?.find(l => l.nodeId === toNodeId ? true : false);
        const virtualTourPlugin = this._viewer?.getPlugin(VirtualTourPlugin) as VirtualTourPlugin;
        if (link) {
            // this._viewer.animate({
            //     yaw: (link.position as Position).yaw,
            //     pitch: (link.position as Position).pitch,
            //     speed: "10rpm"
            // })
            //     .then((complete) => {
            // if (complete) {
            setTimeout(() => {
                virtualTourPlugin.setCurrentNode(toNodeId, undefined, link);
            }, 0);
            // }
            // });
        } else {
            virtualTourPlugin.setCurrentNode(toNodeId);
        }
    }

    private async _setHotspots() {
        if (!this._viewer) return;
        const config = await getConfig();
        const mapPlugin = this._viewer.getPlugin(MapPlugin) as MapPlugin;
        const nodes = this._nodeList.getList();
        const mapHotspots: MapHotspot[] = []
        nodes.forEach((node => {
            if (node.data?.showOnMap && node.gps) {
                const x = Math.floor(((node.gps[0] - config.mapExtend[0]) /
                    (config.mapExtend[2] - config.mapExtend[0])) * config.mapSize.width);
                const y = Math.floor(((config.mapExtend[1] - node.gps[1]) /
                    (config.mapExtend[1] - config.mapExtend[3])) * config.mapSize.height);

                mapHotspots.push({
                    id: "__tour-link__" + node.id,
                    tooltip: node.name,
                    x: x,
                    y: y
                });
            }
        }));
        mapPlugin.clearHotspots();
        mapPlugin.setHotspots(mapHotspots);
    }
}