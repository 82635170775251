export default abstract class AbstractEditor {
    _container: HTMLDivElement;
    _rootPath: string;

    constructor(container: HTMLDivElement, rootPath: string) {
        this._container = container;
        this._rootPath = rootPath;
    }

    abstract show(): void
    abstract hide(): void
    abstract destroy(): void
}